import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Calculadora from './componentes/Calculadora/Calculadora';
import Mantenimiento from './componentes/Mantenimiento/Mantenimiento';
import Portfolio from './componentes/Portfolio/Portfolio';
import Battletech from './componentes/Battletech/Battletech';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigate replace to="/mantenimiento" />} />
      <Route path="/about" element={<Portfolio />} />
      <Route path="/mantenimiento" element={<Mantenimiento />} />
      <Route path="/calculadora" element={<Calculadora />} />
      <Route path="/battletech" element={<Battletech />} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
